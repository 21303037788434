import React from "react";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { parseISO, formatDistanceToNowStrict } from "date-fns";
import { InstantSearch, Configure, Highlight, Hits, SearchBox, Snippet, SortBy } from "react-instantsearch-hooks-web";

import "./App.scss";

const searchClient = instantMeiliSearch("https://api.rocklode.com", "cd4772094069debf884b852704772f728ba73d377b2e4adf7cdeaf1e96d0c46e");
// const searchClient = algoliasearch("latency", "6be0576ff61c053d5f9a3225e2a90f76");

function ZsearchFunction(helper) {
  if (helper.state.query === "") {
    return;
  }
  helper.search();
}
// "id": int(doc['id']),
// "name": str(doc['name']),
// "datasource": str(doc['datasource']),
// "filing_date": str(doc['filing_date']),
// "document_title": str(doc['document_title']),
// "external_document_link": str(doc['external_document_link']),
// "doc_raw_text": str(doc['doc_raw_text']),
function Hit({ hit }) {
  return (
    <article className="hit" id={hit.id}>
      <div className="doc_title">
        <a href={hit.external_document_link} target="_blank">
          {hit.document_title}
        </a>
      </div>
      <div>
        <div className="hit_text">
          <Snippet hit={hit} attribute="doc_raw_text" />
        </div>
        <span className="date-subtitle">
          [ <span className="name_sub">{hit.name}</span> | {formatDistanceToNowStrict(parseISO(hit.filing_date), { addSuffix: true })} | {hit.datasource} ]
        </span>
      </div>
    </article>
  );
}

export function App() {
  return (
    <>
      <InstantSearch searchClient={searchClient} indexName="newsreleases" routing={true} searchFunction={ZsearchFunction}>
        <Configure ruleContexts={[]} attributesToSnippet={["doc_raw_text:50"]} hitsPerPage={25} />

        <div className="container">
          <div>
            <h2>Mining Announcement Search Engine</h2>
            <p>
              Use quotes in your phrase search ie, "john doe" will return complete matches where if you searched john doe both john and doe will try to be matched in the document text. <br />
              Currently 2022 to date are loaded into the system, message <a href="https://twitter.com/rocklode">@rocklode</a> on twitter for feature suggestions
            </p>
          </div>
          <div className="Search">
            <SearchBox placeholder="Search" />
            <SortBy
              items={[
                { label: "Relevance", value: "newsreleases" },
                { label: "Date (asc)", value: "newsreleases:filing_date:asc" },
                { label: "Date (desc)", value: "newsreleases:filing_date:desc" },
              ]}
            />
            <Hits hitComponent={Hit} />
          </div>
        </div>
      </InstantSearch>
    </>
  );
}

export default App;
